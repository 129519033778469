import React, { useState, useEffect, useRef } from 'react';

//! NPM additional packages
import Typist from 'react-typist';
import { gsap, Power3 } from 'gsap';

import ContactBtn from '../contact-btn/ContactBtn';
import NameLogo from '../name-logo/NameLogo';

const PresentationSection = () => {
  const [count, setCount] = useState(1);

  const headerNameRef = useRef(null);
  const hexagonRef = useRef(null);
  const headerRef = useRef(null);
  const titleLine1Ref = useRef(null);
  const titleLine2Ref = useRef(null);
  const titleSubtitleRef = useRef(null);
  const titleTypistRef = useRef(null);
  const titleButtonRef = useRef(null);
  const artRef = useRef(null);

  //* Media query declaration */

  const isMobile = window.matchMedia('(max-device-width: 425px)');
  useEffect(() => {
    headerNameRef.current.scrollTop = 0;
  }, []);
  //! Hexagon
  useEffect(() => {
    gsap.from(hexagonRef.current, {
      opacity: 0.1,
      scale: 0,
      rotate: 360,
      duration: 1,
      delay: 0.75,
      ease: Power3.easeOut,
    });
  }, []);

  useEffect(() => {
    gsap.from(titleLine1Ref.current, {
      opacity: 0,
      y: 50,

      duration: 1,
      delay: 0.75,
      ease: Power3.easeOut,
    });
  }, []);

  //! Name fade away
  useEffect(() => {
    gsap.to(headerNameRef.current, {
      scrollTrigger: {
        trigger: headerNameRef.current,
        start: 'top top',
        end: 'bottom 12%',

        // markers: true,

        toggleActions: 'play none none reverse',
        // scrub: 1.5,
      },
      scale: 0.5,
      // opacity: 0,
      duration: 1,
    });
  }, []);

  //! Pinned art on scroll

  useEffect(() => {
    if (isMobile.matches) {
      gsap.to(artRef.current, {
        scrollTrigger: {
          trigger: artRef.current,
          start: 'top 25%',
          end: '+=1500',
          toggleActions: 'play none none reverse',
          // markers: true,
          pin: true,
          pinSpacing: false,
          scrub: 1.5,
        },
        opacity: 0.1,
        scale: 1.45,
      });
    } else {
      gsap.to(artRef.current, {
        scrollTrigger: {
          trigger: artRef.current,
          toggleActions: 'play none none reverse',
          start: 'center center',
          end: '+=1500',
          scrub: 1,
          pin: true,
          pinSpacing: false,
          // markers: true,
        },
        opacity: 0.1,
        scale: 1.35,
        x: '50%',

        rotate: 5,
      });
    }
  }, []);

  //! Line 1 Title scroll effect
  useEffect(() => {
    if (isMobile.matches) {
      gsap.to(titleLine1Ref.current, {
        scrollTrigger: {
          trigger: titleLine1Ref.current,
          start: 'top 55%',
          end: '+=600',
          toggleActions: 'play none none reverse',
          // markers: true,
          // pin: true,
          // pinSpacing: false,
          scrub: 1.5,
        },
        y: 400,
        scale: 1.5,
      });
    } else {
      gsap.to(titleLine1Ref.current, {
        scrollTrigger: {
          trigger: titleLine1Ref.current,
          toggleActions: 'play none none reverse',
          start: 'center center',
          end: '+=500',

          scrub: 1.5,
          pin: true,

          // markers: true,
        },
        x: '-50%',

        rotate: 5,

        scale: 1.5,
      });
    }
  }, []);

  //! Line 2 of title scroll

  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(titleLine2Ref.current, {
        scrollTrigger: {
          trigger: titleLine2Ref.current,
          start: 'top 75%',
          end: 'center 75%',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '15rem',
        rotate: 5,
      });
    } else {
      gsap.from(titleLine2Ref.current, {
        scrollTrigger: {
          trigger: titleLine2Ref.current,
          toggleActions: 'play none none reverse',
          start: 'top center',
          end: '+=200',
          scrub: 1.5,
          pin: true,
          // markers: true,
        },
        x: '-25%',
        opacity: 0,
        scale: 2,
        rotate: 5,
      });
    }
  }, []);

  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(titleSubtitleRef.current, {
        scrollTrigger: {
          trigger: titleSubtitleRef.current,
          start: 'top 75%',
          end: 'center 75%',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '-15rem',
        rotate: -5,
      });
    } else {
      gsap.from(titleSubtitleRef.current, {
        scrollTrigger: {
          trigger: titleSubtitleRef.current,
          toggleActions: 'play none none reverse',
          start: 'top center',
          end: '+=200',
          scrub: 1.5,
          pin: true,
          // markers: true,
        },
        x: '25%',
        opacity: 0,
        scale: 2,
        rotate: -5,
      });
    }
  }, []);

  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(titleTypistRef.current, {
        scrollTrigger: {
          trigger: titleTypistRef.current,
          start: 'top 75%',
          end: 'center 75%',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        y: '15rem',
      });
    } else {
      gsap.from(titleTypistRef.current, {
        scrollTrigger: {
          trigger: titleTypistRef.current,
          toggleActions: 'play none none reverse',
          start: 'top center',
          end: '+=200',
          scrub: 1.5,
          pin: true,
          // markers: true,
        },
        x: '25%',
        opacity: 0,
        scale: 2,
        rotate: -5,
      });
    }
  }, []);

  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(titleButtonRef.current, {
        scrollTrigger: {
          trigger: titleButtonRef.current,
          start: 'top 75%',
          end: 'center 75%',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        y: '15rem',
      });
    } else {
      gsap.from(titleButtonRef.current, {
        scrollTrigger: {
          trigger: titleButtonRef.current,
          toggleActions: 'play none none reverse',
          start: 'top center',
          end: '+=300',
          scrub: 1.5,
          pin: true,
          // markers: true,
        },
        x: '25%',
        opacity: 0,
        scale: 2.5,
        rotate: -90,
      });
    }
  }, []);

  //! Header slide
  useEffect(() => {
    gsap.from(
      headerRef.current,

      {
        opacity: 0,
        y: -100,
        duration: 1,
      },
      []
    );
    gsap.to(headerRef.current, {
      scrollTrigger: {
        trigger: headerRef.current,
        start: '200 200',
        end: '400 50',

        // markers: true,
        toggleActions: 'play none none reverse',
        scrub: 1.5,
      },
      css: {
        borderRadius: '0 0 0 0',
        backgroundImage: 'linear-gradient(to bottom,#ffff9d,#ffff9d,#ffff9d )',
      },
    });
  }, []);

  //! Infinite text helper for typist
  useEffect(() => {
    setCount(1);

    return () => {
      setCount(0);
    };
  }, [count]);

  return (
    <>
      <section className='hero'>
        <header ref={headerRef} className='header'>
          <div ref={headerNameRef} className='header__name-logo'>
            {' '}
            <NameLogo />
          </div>
        </header>
        <div className='hero__main-content'>
          <div ref={artRef} className='hero__images-container'>
            <div className='hero__image-container'>
              <div ref={hexagonRef} className='hexagon'></div>
            </div>
          </div>
          <div className='hero__text-container'>
            <h1 ref={titleLine1Ref} className='hero__title'>
              Hi! I&apos;m Cristian.
              <br />
            </h1>
          </div>
        </div>
        <div className='hero__story-container'>
          <h2 className='hero__title--line2' ref={titleLine2Ref}>
            A full-stack developer.
          </h2>
          <p ref={titleSubtitleRef} className='hero__title--sub'>
            I <strong className='about__underline'>design</strong>,{' '}
            <strong className='about__underline about__underline--second'>
              develop
            </strong>{' '}
            and{' '}
            <strong className='about__underline about__underline--third'>
              deploy
            </strong>{' '}
            modern web applications.
          </p>
          <br />

          <div ref={titleTypistRef} className='hero__infinite-text'>
            {count ? (
              <>
                <p className='hero__title--sub'>
                  Let&apos;s get in touch and build <br />
                </p>
                <Typist
                  cursor={{ hideWhenDone: false, element: '|' }}
                  avgTypingDelay={75}
                  onTypingDone={() => setCount(0)}
                >
                  <Typist.Delay ms={500} />
                  <span className='hero__span-special'>AWESOME PROJECTS</span>

                  <Typist.Backspace count={17} delay={1500} />
                  <span className='hero__span-special'>
                    ENTERPRISE APPLICATIONS
                  </span>
                  <Typist.Backspace count={24} delay={1500} />
                  <span className='hero__span-special'>IMMERSIVE WEBSITES</span>
                  <Typist.Backspace count={18} delay={1500} />
                  <span className='hero__span-special'> SCALABLE APIs</span>
                  <Typist.Backspace count={13} delay={1500} />
                  <span className='hero__span-special'>THE WEB</span>
                  <Typist.Backspace count={7} delay={1500} />
                </Typist>
              </>
            ) : (
              ''
            )}
          </div>

          <div className='hero__about-btn' ref={titleButtonRef}>
            <ContactBtn hrefUrl='/about' text='About me' />
          </div>
        </div>
      </section>
    </>
  );
};

export default PresentationSection;
