import React from "react";
import PropTypes from "prop-types";

const NameLogo = ({ dropdownOpen }) => {
	//! Refs

	return (
		<div className={dropdownOpen ? "display-none" : "name-box"}>
			<div className="title">
				<span className="block"></span>
				<h1>
          Cristian Buciu<span></span>
				</h1>
			</div>

			<div className="role">
				<div className="block"></div>
				<p className="block--text">Full Stack Developer</p>
			</div>
		</div>
	);
};

NameLogo.propTypes = {
	dropdownOpen: PropTypes.bool.isRequired
};

export default NameLogo;
