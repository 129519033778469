import React from 'react';

//! NPM extra packages
import { Element } from 'react-scroll';

import PresentationSection from '../presentation-section/PresentationSection';

import TechnologiesSection from '../technologies-section/TechnologiesSection';
import WorkSection from '../work-section/WorkSection';
import ContactSection from '../contact-section/ContactSection';

//!==========================================================
const SectionsContainer = () => {
  return (
    <>
      <div className='sections-container'>
        <Element id='presentation-section' name='presentation-section'>
          <PresentationSection />
        </Element>
        <Element id='work-section' name='work-section'>
          <WorkSection />
        </Element>
        <Element id='technologies-section' name='technologies-section'>
          <TechnologiesSection />
        </Element>

        <Element id='contact-section' name='contact-section'>
          <ContactSection />
        </Element>
      </div>
    </>
  );
};

export default SectionsContainer;
