import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { toggleElementClickState } from '../../redux/pointer/pointer.actions';

import { ReactComponent as Close } from '../../assets/hand-drawn-icons/delete-hand-drawn-cross-symbol-outline.svg';

const CloseIcon = ({ handler }) => {
  const dispatch = useDispatch();
  return (
    <div className='close__close-container'>
      <Close
        onClick={handler}
        onMouseEnter={() => dispatch(toggleElementClickState(true))}
        onMouseLeave={() => dispatch(toggleElementClickState(false))}
        className='close__close'
      />
      <span className='close__close-text'>close</span>
    </div>
  );
};

CloseIcon.propTypes = {
  handler: PropTypes.func.isRequired,
};

export default CloseIcon;
