import React, { useRef, useEffect } from 'react';

//! Data
import { tools } from './tools';

import TechIcon from '../tech-icon/TechIcon';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

//! ========================================================
const TechnologiesSection = () => {
  //! Media query mobile devices
  const isMobile = window.matchMedia('(max-device-width: 425px)');

  //! Hooks

  const techTitleRef = useRef(null);
  const techTitleTRef = useRef(null);
  const techSubtitleRef = useRef(null);
  //* sections refs */
  const frontEndRef = useRef(null);
  const backEndRef = useRef(null);
  const designRef = useRef(null);
  const analyticsRef = useRef(null);
  const developerToolsRef = useRef(null);

  const toolkitRefs = [
    frontEndRef,
    backEndRef,
    designRef,
    analyticsRef,
    developerToolsRef,
  ];

  //* sub section titles refs */
  const frontEndTitleRef = useRef(null);
  const backEndTitleRef = useRef(null);
  const designTitleRef = useRef(null);
  const analyticsTitleRef = useRef(null);
  const developerToolsTitleRef = useRef(null);

  const toolkitTitleRefs = [
    frontEndTitleRef,
    backEndTitleRef,
    designTitleRef,
    analyticsTitleRef,
    developerToolsTitleRef,
  ];

  //! Title T ref animations refuseEffect(() => {
  useEffect(() => {
    gsap.from(
      techTitleTRef.current,
      {
        scrollTrigger: {
          trigger: techTitleTRef.current,
          start: 'top center',
          end: 'center center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '-15rem',
        rotate: -5,
      },
      []
    );
  }, []);

  //! Subsection titles gsap scroll animations
  useEffect(() => {
    if (isMobile.matches) {
      toolkitTitleRefs.map((el) =>
        gsap.from(
          el.current,
          {
            scrollTrigger: {
              trigger: el.current,
              start: 'top 60%',
              end: 'bottom 60%',
              toggleActions: 'play none none reverse',
              // markers: true,
              scrub: 1.5,
            },
            opacity: 0,
            x: '15rem',
            rotate: 5,
            // duration: 1,
          },
          []
        )
      );
    } else {
      toolkitTitleRefs.map((el) =>
        gsap.from(
          el.current,
          {
            scrollTrigger: {
              trigger: el.current,
              start: '-10rem center',
              end: 'bottom center',
              toggleActions: 'play none none reverse',
              // markers: true,
              scrub: 1.5,
            },
            opacity: 0,
            x: '15rem',
            rotate: 5,
            // duration: 1,
          },
          []
        )
      );
    }
  }, []);

  //! Subsection  gsap scroll animations
  useEffect(() => {
    if (isMobile.matches) {
      toolkitRefs.map((el) =>
        gsap.from(
          el.current,
          {
            scrollTrigger: {
              trigger: el.current,
              start: 'top 75%',
              end: 'center 75%',
              toggleActions: 'play none none reverse',
              // markers: true,
              scrub: 1.5,
            },
            opacity: 0,
            y: '15rem',
            rotate: 15,

            // duration: 1,
          },
          []
        )
      );
    } else {
      toolkitRefs.map((el) =>
        gsap.from(
          el.current,
          {
            scrollTrigger: {
              trigger: el.current,
              start: 'top 60%',
              end: 'center center',
              toggleActions: 'play none none reverse',
              // markers: true,
              scrub: 1.5,
            },
            opacity: 0,
            y: '15rem',
            rotate: 15,

            // duration: 1,
          },
          []
        )
      );
    }
  }, []);

  //! GSAP declaration
  gsap.registerPlugin(ScrollTrigger);

  //! Title Scroll effect
  useEffect(() => {
    gsap.from(
      techTitleRef.current,
      {
        scrollTrigger: {
          trigger: techTitleRef.current,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '15rem',
        rotate: 5,
        duration: 1,
      },
      []
    );
  }, []);

  //! Subtitle scroll animation
  useEffect(() => {
    gsap.from(
      techSubtitleRef.current,
      {
        scrollTrigger: {
          trigger: techSubtitleRef.current,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        y: '5rem',
        x: '-5rem',
        rotate: 5,
      },
      []
    );
  }, []);

  return (
    <div className='technologies'>
      <h1 className='technologies__title'>
        <span
          ref={techTitleTRef}
          style={{
            color: '#eb487e',
            display: 'inline-block',
            transform: 'rotate(-5deg) scale(1.5)',
          }}
        >
          T
        </span>
        <span style={{ display: 'inline-block' }} ref={techTitleRef}>
          oolkit
        </span>
      </h1>
      <p ref={techSubtitleRef} className='technologies__subtitle'>
        The set of the best practices, tools & technologies I usually use
      </p>
      <h2
        ref={frontEndTitleRef}
        style={{
          color: '#FF5722',
          backgroundColor: 'rgba(255, 87, 34,.1)',
        }}
        className='technologies__tools-title'
      >
        {tools.frontEnd.name}
      </h2>
      <div
        ref={frontEndRef}
        className='technologies__tools-container technologies__tools-front-end'
      >
        {tools.frontEnd.tools.map((el) => (
          <TechIcon
            key={el.id}
            iconSrc={el.image}
            title={el.title}
            subtitle={el.subtitle}
            link={el.link}
          />
        ))}
      </div>
      <h2
        ref={backEndTitleRef}
        style={{
          color: 'rgb(52, 221, 37)',
          backgroundColor: 'rgba(52, 221, 37,.1)',
        }}
        className='technologies__tools-title'
      >
        {tools.backEnd.name}
      </h2>
      <div
        ref={backEndRef}
        className='technologies__tools-container technologies__tools-back-end'
      >
        {tools.backEnd.tools.map((el) => (
          <TechIcon
            key={el.id}
            iconSrc={el.image}
            title={el.title}
            subtitle={el.subtitle}
            link={el.link}
          />
        ))}
      </div>
      <h2
        ref={designTitleRef}
        style={{
          color: '#A259FF',
          backgroundColor: 'rgba(162, 89, 255,.1)',
        }}
        className='technologies__tools-title'
      >
        {tools.design.name}
      </h2>
      <div
        ref={designRef}
        className='technologies__tools-container technologies__tools-design'
      >
        {tools.design.tools.map((el) => (
          <TechIcon
            key={el.id}
            iconSrc={el.image}
            title={el.title}
            subtitle={el.subtitle}
            link={el.link}
          />
        ))}
      </div>
      <h2
        ref={analyticsTitleRef}
        style={{
          color: '#E37400',
          backgroundColor: 'rgba(227, 116, 0, .1)',
        }}
        className='technologies__tools-title'
      >
        {tools.analytics.name}
      </h2>
      <div
        ref={analyticsRef}
        className='technologies__tools-container technologies__tools-analytics'
      >
        {tools.analytics.tools.map((el) => (
          <TechIcon
            key={el.id}
            iconSrc={el.image}
            title={el.title}
            subtitle={el.subtitle}
            link={el.link}
          />
        ))}
      </div>
      <h2
        ref={developerToolsTitleRef}
        style={{
          color: '#25C7B7',
          backgroundColor: 'rgba(37, 199, 183, .1)',
        }}
        className='technologies__tools-title'
      >
        {tools.developerTools.name}
      </h2>
      <div
        ref={developerToolsRef}
        className='technologies__tools-container technologies__tools-developer'
      >
        {tools.developerTools.tools.map((el) => (
          <TechIcon
            key={el.id}
            iconSrc={el.image}
            title={el.title}
            subtitle={el.subtitle}
            link={el.link}
          />
        ))}
      </div>
    </div>
  );
};

export default TechnologiesSection;
