import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import {
  toggleElementHoverState,
  toggleElementSlideState,
  toggleElementClickState,
  toggleElementScrollState,
} from '../../redux/pointer/pointer.actions';
import CloseIcon from '../../components/close-icon/CloseIcon';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProjectModal = ({
  title,
  modalToggleOpen,
  modalOpen,
  caption,
  tech,
  images,
  children,
}) => {
  const dispatch = useDispatch();

  const [slideDown, setSlideDown] = useState(false);

  //* Carousel settings */
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    swipeToSlide: true,
    touchMove: true,
    adaptiveHeight: true,
    variableWidth: true,

    autoplay: true,
    pauseOnHover: true,
    focusOnSelect: true,
  };

  return ReactDOM.createPortal(
    <>
      {modalOpen ? (
        <div
          className={
            slideDown
              ? 'modal-container modal-container__close-animation'
              : 'modal-container'
          }
        >
          <div className={slideDown ? 'modal modal__slide-animation' : 'modal'}>
            <CloseIcon
              handler={() => {
                setSlideDown(true);
                dispatch(toggleElementClickState(false));
                setTimeout(() => {
                  modalToggleOpen();
                  setSlideDown(false);
                }, 500);
              }}
            />
            <div
              onMouseEnter={() => dispatch(toggleElementScrollState(true))}
              onMouseLeave={() => dispatch(toggleElementScrollState(false))}
              className='modal__title'
            >
              <div className='modal__logo'>{children}</div>
              <div className='modal__header-title-container'>
                <h1 className='modal__header-title-container--heading'>
                  {title}
                </h1>
                <p className='modal__header-title-container--caption'>
                  {caption}
                </p>
              </div>
            </div>
            <div className='modal__tech'>
              <div
                onMouseEnter={() => dispatch(toggleElementHoverState(true))}
                onMouseLeave={() => dispatch(toggleElementHoverState(false))}
                className='modal__tech--cards'
              >
                {tech.map((el, idx) => (
                  <div key={idx} className='modal__tech--card'>
                    {el}
                  </div>
                ))}
              </div>
            </div>
            <div className='modal__content'>
              <Slider className='modal__slider' {...settings}>
                {images.map((el, idx) => (
                  <div
                    onMouseEnter={() => dispatch(toggleElementSlideState(true))}
                    onMouseLeave={() =>
                      dispatch(toggleElementSlideState(false))
                    }
                    key={idx}
                    className='modal__slide-container'
                  >
                    <img className='modal__image' src={el} alt='slide' />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : null}
    </>,
    document.getElementById('modal-root')
  );
};

export default ProjectModal;
