import { pointerConstants } from "./pointer.constants";

export const elementHoverReducer = (
	state = { elementHoverState: false },
	action
) => {
	switch (action.type) {
	case pointerConstants.ELEMENT_HOVER_STATE:
		return {
			elementHoverState: action.payload,
		};

	default:
		return state;
	}
};

export const elementScrollReducer = (
	state = { elementScrollState: false },
	action
) => {
	switch (action.type) {
	case pointerConstants.ELEMENT_SCROLL_STATE:
		return {
			elementScrollState: action.payload,
		};
	default:
		return state;
	}
};

export const elementClickReducer = (
	state = { elementClickState: false },
	action
) => {
	switch (action.type) {
	case pointerConstants.ELEMENT_CLICK_STATE:
		return {
			elementClickState: action.payload,
		};
	default:
		return state;
	}
};

export const elementSlideReducer = (
	state = { elementSlideState: false },
	action
) => {
	switch (action.type) {
	case pointerConstants.ELEMENT_SLIDE_STATE:
		return {
			elementSlideState: action.payload,
		};
	default:
		return state;
	}
};
