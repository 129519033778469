import React, { useRef, useEffect } from 'react';
import ProjectCard from '../project-card/ProjectCard';
import ProjectCaption from '../project-caption/ProjectCaption';
import felinaLogo from '../../assets/felina/cat.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GiTechnoHeart } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import { toggleElementHoverState } from '../../redux/pointer/pointer.actions';

const WorkSection = () => {
  const dispatch = useDispatch();

  const isMobile = window.matchMedia('(max-device-width: 425px)');

  const workTitleRRef = useRef(null);
  const workTitleWRef = useRef(null);
  const workTitlePartial1Ref = useRef(null);
  const workTitlePartial2Ref = useRef(null);
  const zeroProjectRef = useRef(null);
  const firstProjectRef = useRef(null);
  const secondProjectRef = useRef(null);
  const thirdProjectRef = useRef(null);
  const forthProjectRef = useRef(null);
  const fifthProjectRef = useRef(null);
  const workSubtitleRef1 = useRef(null);
  const workSubtitleRef2 = useRef(null);
  const workSubtitleRef3 = useRef(null);

  const subtitleRefs = [workSubtitleRef1, workSubtitleRef2, workSubtitleRef3];

  //! GSAP declaration
  gsap.registerPlugin(ScrollTrigger);

  //! Title Scroll effect

  useEffect(() => {
    gsap.from(
      workTitleRRef.current,
      {
        scrollTrigger: {
          trigger: workTitleRRef.current,
          start: 'top center',
          end: 'center center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '-15rem',
        rotate: -5,
      },
      []
    );
    gsap.from(
      workTitleWRef.current,
      {
        scrollTrigger: {
          trigger: workTitleWRef.current,
          start: 'top center',
          end: 'center center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        x: '15rem',
        rotate: 5,
      },
      []
    );
  }, []);

  useEffect(() => {
    gsap.from(
      workTitlePartial1Ref.current,
      {
        scrollTrigger: {
          trigger: workTitlePartial1Ref.current,
          start: 'top center',
          end: 'center center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        y: '5rem',
      },
      []
    );
    gsap.from(
      workTitlePartial2Ref.current,
      {
        scrollTrigger: {
          trigger: workTitlePartial2Ref.current,
          start: 'top 40%',
          end: 'center center',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
        },
        opacity: 0,
        y: '-5rem',
      },
      []
    );
  }, []);

  useEffect(() => {
    subtitleRefs.map((el) =>
      gsap.from(
        el.current,
        {
          scrollTrigger: {
            trigger: el.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          y: '5rem',
          x: '-5rem',
          rotate: 5,
        },
        []
      )
    );
  }, []);

  //! Tech heart projects scroll
  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        zeroProjectRef.current,
        {
          scrollTrigger: {
            trigger: zeroProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,

            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    } else {
      gsap.from(
        zeroProjectRef.current,
        {
          scrollTrigger: {
            trigger: zeroProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    }
  }, []);

  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        firstProjectRef.current,
        {
          scrollTrigger: {
            trigger: firstProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,

            scrub: 1.5,
          },
          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    } else {
      gsap.from(
        firstProjectRef.current,
        {
          scrollTrigger: {
            trigger: firstProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    }
  }, []);

  //! Felina projects scroll
  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        secondProjectRef.current,
        {
          scrollTrigger: {
            trigger: secondProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    } else {
      gsap.from(
        secondProjectRef.current,
        {
          scrollTrigger: {
            trigger: secondProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    }
  }, []);

  //! Omnitours heart projects scroll
  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        thirdProjectRef.current,
        {
          scrollTrigger: {
            trigger: thirdProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },

          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    } else {
      gsap.from(
        thirdProjectRef.current,
        {
          scrollTrigger: {
            trigger: thirdProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },

          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    }
  }, []);

  //! Nexter projects scroll
  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        forthProjectRef.current,
        {
          scrollTrigger: {
            trigger: forthProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    } else {
      gsap.from(
        forthProjectRef.current,
        {
          scrollTrigger: {
            trigger: forthProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '15rem',
          rotate: 5,
        },
        []
      );
    }
  }, []);

  //! Giphy projects scroll
  useEffect(() => {
    if (isMobile.matches) {
      gsap.from(
        fifthProjectRef.current,
        {
          scrollTrigger: {
            trigger: fifthProjectRef.current,
            start: 'top 75%',
            end: 'center 75%',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    } else {
      gsap.from(
        fifthProjectRef.current,
        {
          scrollTrigger: {
            trigger: fifthProjectRef.current,
            start: 'top center',
            end: 'center center',
            toggleActions: 'play none none reverse',
            // markers: true,
            scrub: 1.5,
          },
          opacity: 0,
          x: '-15rem',
          rotate: -5,
        },
        []
      );
    }
  }, []);

  return (
    <>
      <div className='work-section'>
        <h1 className='work-section__title'>
          <span
            ref={workTitleRRef}
            style={{
              color: '#eb487e',
              display: 'inline-block',
              transform: 'rotate(5deg) scale(1.5)',
            }}
          >
            R
          </span>
          <span style={{ display: 'inline-block' }} ref={workTitlePartial1Ref}>
            ecent
          </span>
          <br />{' '}
          <span className='work-section__title--span'>
            <span
              ref={workTitleWRef}
              style={{
                color: '#2acdc1',
                display: 'inline-block',
                transform: 'rotate(-5deg) scale(1.5)',
              }}
            >
              W
            </span>

            <span
              style={{ display: 'inline-block' }}
              ref={workTitlePartial2Ref}
            >
              ork
            </span>
          </span>
        </h1>
        <div
          onMouseEnter={() => dispatch(toggleElementHoverState(true))}
          onMouseLeave={() => dispatch(toggleElementHoverState(false))}
        >
          <p ref={workSubtitleRef1} className='work-section__subtitle mb-xs'>
            At the moment I work for one of the biggest software companies in
            Spain, participating in governmental projects or projects for top
            banks not only from Spain but from Andorra, France, or Portugal.
          </p>
          <p ref={workSubtitleRef3} className='work-section__subtitle mb-xl'>
            I also have a range of personal projects from beautiful front-end
            landing pages to immersive fully working full-stack applications.
          </p>
        </div>
        {/* <div
          ref={zeroProjectRef}
          className=' work-section__project work-section__project--atomix'
        >
          <ProjectCaption
            cName='project-caption__filler project-caption__filler-0'
            link='#'
            caption='Atomix custom components'
            style={{ color: 'white' }}
            tech={[
              'react',
              'next js',
              'framer motion',
              'typescript',
              'styled-components',
              'storybook',
            ]}
            linkColor='atomix'
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256780/portfolio/Screenshot_at_Mar_02_22-25-07_uaytwy.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256781/portfolio/Screenshot_at_Mar_02_22-26-26_x3hjgm.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256780/portfolio/Screenshot_at_Mar_02_22-24-08_vrh8sl.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256780/portfolio/localhost_8888__path__story_components-similarprojectsgrid--four-projects_globals_backgrounds.grid_false_backgrounds.value__hex_F8F8F8_iPhone_6_7_8_copy_fra7xg.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256780/portfolio/localhost_8888__path__story_components-similarprojectsgrid--four-projects_globals_backgrounds.grid_false_backgrounds.value__hex_F8F8F8_iPhone_6_7_8_wsgnn1.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/v1646256781/portfolio/localhost_8888__path__story_components-inframe-carousel--light-mode_globals_backgrounds.grid_false_backgrounds.value__hex_F8F8F8_iPad_s8si7q.webp',
            ]}
            title=''
            subtitle='Digital. Innovation.'
          >
            <img
              alt='atomix project logo'
              src={atomix}
              className='project-caption__project-logo'
              style={{ width: '60%', height: 'auto' }}
            />{' '}
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,w_1030/v1646257541/portfolio/atomix-project-mobile_xv64yy.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/v1646254358/portfolio/atomix-project_xo5hl6.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='first-project'
          />
        </div>{' '} */}
        {/* ==================================================== */}
        <div
          ref={firstProjectRef}
          className=' work-section__project work-section__project--cyber'
        >
          <ProjectCaption
            cName='project-caption__filler project-caption__filler-2'
            link='https://github.com/CristianBuciu/cyber-heart'
            caption='Fully functional eCommerce store'
            tech={[
              'react',
              'redux',
              'gsap',
              'node',
              'mongoDB',
              'express',
              'NGINX',
              'VPS',
            ]}
            linkColor='cyber'
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901020/portfolio/tech-heart/devox.dev__Full_HD_sfqknb.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901007/portfolio/tech-heart/devox.dev__Full_HD_3_ob0rhv.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901763/portfolio/tech-heart/product-page_fbfywn.webp',

              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901006/portfolio/tech-heart/devox.dev__Full_HD_1_i4fzqe.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901012/portfolio/tech-heart/devox.dev__Full_HD_8_rjylbz.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901008/portfolio/tech-heart/devox.dev__Full_HD_4_hxzevx.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901010/portfolio/tech-heart/devox.dev__Full_HD_6_ipdyv8.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901009/portfolio/tech-heart/devox.dev__Full_HD_5_bclja4.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901015/portfolio/tech-heart/devox.dev__Full_HD_11_su3hsv.webp',

              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901016/portfolio/tech-heart/devox.dev__Full_HD_12_ti9qip.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901017/portfolio/tech-heart/devox.dev__Full_HD_13_qsntil.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1621062884/portfolio/tech-heart/Cyber_Heart_yrtiy7.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901011/portfolio/tech-heart/devox.dev__Full_HD_7_d0bjgp.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901018/portfolio/tech-heart/devox.dev__Full_HD_14_fch322.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901763/portfolio/tech-heart/offers-hd_bpw70p.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901007/portfolio/tech-heart/devox.dev__Full_HD_2_bpxmn4.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620901023/portfolio/tech-heart/tech-heart-mobile-menu-view_lezfed.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620902193/portfolio/tech-heart/cart-mobile_cyzszp.webp',
            ]}
            title='Cyber Heart'
            subtitle='Electronic Store'
          >
            <GiTechnoHeart className='project-caption__project-icon cyber-heart-animation' />
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623140364/portfolio/project%20cards/tech_heart_card_jw9gyp.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/v1623122990/portfolio/project%20cards/tech_heart_card_z0h4w5.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='first-project'
          />
        </div>{' '}
        <div
          ref={secondProjectRef}
          className='work-section__project work-section__project--felina'
        >
          <ProjectCaption
            cName='project-caption__filler project-caption__filler-1'
            link='https://felina-fashion.netlify.app'
            caption='Fully functional clothing store'
            tech={['react', 'redux', 'gsap', 'firebase', 'sass']}
            linkColor='felina'
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895127/portfolio/felina/felina-main-hd_derooa.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1621960087/portfolio/felina/localhost_3001__iPhone_6_7_8_bhvm2a.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620930683/portfolio/felina/product-hd_wlehs6.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620930682/portfolio/felina/dropdown-hd_oqsk5s.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620930680/portfolio/felina/dropdown-mobile_xdbjt7.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1621960257/portfolio/felina/localhost_3001__Full_HD_sobxoj.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1621960402/portfolio/felina/felina-fashion.netlify.app__iPhone_6_7_8_pthdp1.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895131/portfolio/felina/thirsty-morse-ce9404.netlify.app__Full_HD_2_cfuzms.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895134/portfolio/felina/view-mode-1_wm7a4n.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895130/portfolio/felina/shopping-bag-view_xexyxo.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895129/portfolio/felina/products-mobile-view_lanobo.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620895128/portfolio/felina/menu-mobile-view_ieduyr.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620900171/portfolio/felina/Felina-mobile-view-mode_vf6hya.webp',
            ]}
            title='FEL!NA'
            subtitle='Fierce Fashion'
          >
            <img
              alt='felina project logo'
              src={felinaLogo}
              className='project-caption__project-logo'
            />{' '}
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623140363/portfolio/project%20cards/Felina-project_bmyppx.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/v1623122990/portfolio/project%20cards/Felina-project_mmcl7u.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='second-project'
          />
        </div>
        <div
          ref={thirdProjectRef}
          className='work-section__project work-section__project--omnitours'
        >
          <ProjectCaption
            cName='project-caption__filler project-caption__filler-4'
            link='https://omnitours.netlify.app/'
            tech={['react', 'redux', 'gsap', 'sass']}
            linkColor='omnitours'
            style={{ color: 'white' }}
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620902848/portfolio/omnitours/desktop-header_eetieg.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620902850/portfolio/omnitours/phone-header_kaa0ro.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903251/portfolio/omnitours/2-hd_tknzcs.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903405/portfolio/omnitours/mid-mobile_nunufr.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903252/portfolio/omnitours/3-hd_aa7nzg.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903252/portfolio/omnitours/cards-mobile_b8paw7.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903254/portfolio/omnitours/menu-hd_vh1sk5.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620903255/portfolio/omnitours/menu-mobile_mzw2wj.webp',
            ]}
            title=' Omnitours'
            subtitle='Explore the Wild'
            caption='Modern design for a holiday maker company made in React'
          >
            <img
              src='https://res.cloudinary.com/c1oud9/image/upload/v1621059305/portfolio/2038466_wyylrg.webp'
              className='project-caption__project-logo'
              alt='omnitours project logo'
            />{' '}
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623140365/portfolio/project%20cards/omnitours_v9pjnt.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623232637/portfolio/project%20cards/omnitours_clqpsf.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='third-project'
          />
        </div>
        <div
          ref={forthProjectRef}
          className='work-section__project work-section__project--nexter'
        >
          <ProjectCaption
            cName='project-caption__filler project-caption__filler-3'
            link='https://crown-estate.netlify.app'
            tech={['react', 'redux', 'gsap', 'sass']}
            linkColor='nexter'
            title=' nexter'
            subtitle='Find Your Perfect Home'
            caption='Beautiful real-estate landing page done in React'
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620911612/portfolio/nexter/hero-hd_zuhi1y.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620911613/portfolio/nexter/hero-mobile_jmj4k3.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916829/portfolio/nexter/key-features-mobile_qhmha4.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916830/portfolio/nexter/key-features-hd_vkugyk.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916827/portfolio/nexter/homes-hd_hqjymb.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916830/portfolio/nexter/homes-mobile_pknzbi.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916833/portfolio/nexter/description-hd_cigjii.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916831/portfolio/nexter/testimonials-hd_bk0fig.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916832/portfolio/nexter/testimonials-mobile_xtez8e.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/q_25/v1620916835/portfolio/nexter/grid-hd_depjnj.webp',
            ]}
          >
            <img
              src='https://res.cloudinary.com/c1oud9/image/upload/q_25/v1616678372/nexter-homes/favicon_b1huhh.webp'
              className='project-caption__project-logo'
              alt='page logo'
            />{' '}
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623140365/portfolio/project%20cards/nexter_zwysyl.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/v1623134314/portfolio/project%20cards/nexter_rngeqr.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='forth-project'
          />
        </div>
        <div
          ref={fifthProjectRef}
          className='work-section__project work-section__project--giphy'
        >
          <ProjectCaption
            style={{ color: 'white' }}
            cName='project-caption__filler project-caption__filler-5'
            link='https://github.com/CristianBuciu/verse-giphy-task'
            tech={['react', 'redux', 'sass', 'nodejs', 'express', 'nginx']}
            linkColor='giphy'
            title=' GIPHi'
            subtitle='Fun in pixels'
            caption="A clone of the popular Gif website, GIPHY. It's using GIPHY's API."
            imagesSrc={[
              'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1000,q_50/v1623088485/portfolio/GIPHY%20Clone/G_P%CA%8E_-_fun_in_pixels_phone_zybt8c.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1000,q_50/v1623088675/portfolio/GIPHY%20Clone/omniweb.dev_1_cd0yyh.webp',
              'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1000,q_50/v1623088486/portfolio/GIPHY%20Clone/G_P%CA%8E_-_fun_in_pixels_tablet_sjdhqx.png',
            ]}
          >
            <img
              src='https://cdn.worldvectorlogo.com/logos/giphy-logo-1.svg'
              className='project-caption__project-logo'
              alt='giphy project logo'
            />{' '}
          </ProjectCaption>
          <ProjectCard
            imgSrc={
              isMobile.matches
                ? 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_900/v1623143201/portfolio/project%20cards/G_P%CA%AE%CA%8E_kiqaxy.webp'
                : 'https://res.cloudinary.com/c1oud9/image/upload/c_scale,h_1200/v1623122992/portfolio/project%20cards/G_P%CA%AE%CA%8E_ivepez.webp'
            }
            scrollClass='project-card__mouse-scroll-icon--big-card'
            cName='fifth-project'
          />
        </div>
      </div>
    </>
  );
};

export default WorkSection;
