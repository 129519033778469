import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import SectionsContainer from './components/sections-container/SectionsContainer';
import NavBar from './components/nav-bar/NavBar';

import About from './pages/about/About';

//! npm extra packages
import { motion, useMotionValue, useSpring } from 'framer-motion';
//* Media query hook for react */
import { useMediaPredicate } from 'react-media-hook';
import * as Scroll from 'react-scroll';

import { useSelector } from 'react-redux';

//! GOOGLE ANALYTICS
import ReactGa from 'react-ga';
import Footer from './components/footer/Footer';

//!=================================================

function App() {
	//! Hooks
	let location = useLocation();

	//! GOOGLE ANALYTICS UseEffect
	useEffect(() => {
		if (!window.GA_INITIALIZED) {
			ReactGa.initialize('UA-197754792-1');
			window.GA_INITIALIZED = true;
		}
		ReactGa.set({ page: location.pathname, anonymizeIp: true });
		ReactGa.pageview(location.pathname);
	}, [location]);

	//! Framer Motion for mouse change
	const cursorX = useMotionValue(-100);
	const cursorY = useMotionValue(-100);
	const springConfig = { damping: 50, stiffness: 1000 };
	const cursorXSpring = useSpring(cursorX, springConfig);
	const cursorYSpring = useSpring(cursorY, springConfig);

	//* Media query declaration */
	const fullHdQuery = useMediaPredicate('(max-width: 1260px)');

	//! Get hover state of elements to add a custom class

	const elementHover = useSelector((state) => state.elementHoverState);
	const { elementHoverState } = elementHover;

	const elementScroll = useSelector((state) => state.elementScrollState);
	const { elementScrollState } = elementScroll;

	const elementClick = useSelector((state) => state.elementClickState);
	const { elementClickState } = elementClick;

	const elementSlide = useSelector((state) => state.elementSlideState);
	const { elementSlideState } = elementSlide;

	const cursorDisplacement = elementHoverState
		? fullHdQuery
			? 7.5
			: 15
		: fullHdQuery
			? 15
			: 30;

	//! Use effect for mouse changing
	useEffect(() => {
		const moveCursor = (e) => {
			cursorX.set(e.clientX - cursorDisplacement);
			cursorY.set(e.clientY - cursorDisplacement);
		};
		window.addEventListener('mousemove', moveCursor);

		return () => {
			window.removeEventListener('mousemove', moveCursor);
		};
	}, [elementHover, elementScroll, cursorX, cursorY, cursorDisplacement]);

	//! Scroll library declare and function for
	let scroll = Scroll.animateScroll;

	useEffect(() => {
		scroll.scrollToTop();
	}, []);

	return (
		<div className='app'>
			<div className='app-background'></div>
			<motion.div
				className={
					elementHoverState
						? 'cursor cursor__hover'
						: elementScrollState
							? 'cursor'
							: elementClickState
								? 'cursor__click'
								: elementSlideState
									? 'cursor__slide'
									: 'cursor__scroll'
				}
				style={{
					translateX: cursorXSpring,
					translateY: cursorYSpring,
				}}
			/>

			<Switch>
				<Route exact path='/'>
					<NavBar />
					<SectionsContainer />
				</Route>

				<Route exact path='/about'>
					<About />
				</Route>
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
