import { pointerConstants } from "./pointer.constants";

export const toggleElementHoverState = (data) => ({
	type: pointerConstants.ELEMENT_HOVER_STATE,
	payload: data,
});

export const toggleElementScrollState = (data) => ({
	type: pointerConstants.ELEMENT_SCROLL_STATE,
	payload: data,
});

export const toggleElementClickState = (data) => ({
	type: pointerConstants.ELEMENT_CLICK_STATE,
	payload: data,
});

export const toggleElementSlideState = (data) => ({
	type: pointerConstants.ELEMENT_SLIDE_STATE,
	payload: data,
});
