import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

//! Reducers
import {
	elementClickReducer,
	elementHoverReducer,
	elementScrollReducer,
	elementSlideReducer,
} from "./pointer/pointer.reducers";

const reducer = combineReducers({
	elementHoverState: elementHoverReducer,
	elementScrollState: elementScrollReducer,
	elementClickState: elementClickReducer,
	elementSlideState: elementSlideReducer,
});

const middleware = [thunk];

const store = createStore(
	reducer,
	composeWithDevTools(
		applyMiddleware(...middleware)
		// other store enhancers if any
	)
);

export default store;
