import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../assets/svg/back_arrow_draw.svg';
import Curriculum from '../../curriculum-vitae/CV Cristian Buciu.pdf';

import { gsap, Power3 } from 'gsap';

import { Element } from 'react-scroll';

import {
  toggleElementClickState,
  toggleElementHoverState,
} from '../../redux/pointer/pointer.actions';
import { useDispatch } from 'react-redux';

const About = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const portraitRef = useRef(null);
  const introTextRef = useRef(null);
  const portraitImgRef = useRef(null);

  useEffect(() => {
    gsap.from(portraitRef.current, {
      opacity: 0,

      y: '15rem',

      duration: 0.5,
      delay: 0.5,

      ease: Power3.easeOut,
    });

    gsap.from(introTextRef.current, {
      opacity: 0,
      y: '15rem',
      duration: 0.75,
      delay: 0.75,
      ease: Power3.easeOut,
    });
  }, []);

  return (
    <div className='about'>
      <Element id='about-top'>
        <BackArrow
          onClick={() => {
            history.push('/');
            dispatch(toggleElementClickState(false));
          }}
          onMouseEnter={() => dispatch(toggleElementClickState(true))}
          onMouseLeave={() => dispatch(toggleElementClickState(false))}
          className='about__go-back'
        />{' '}
        <span className='about__go-back--text'>go back</span>
      </Element>
      <div
        onMouseEnter={() => dispatch(toggleElementClickState(true))}
        onMouseLeave={() => dispatch(toggleElementClickState(false))}
        className='download-cv'
      >
        <a
          className='download-cv__link'
          href={Curriculum}
          target='_blank'
          rel='noreferrer'
        >
          {' '}
          Download CV
        </a>
      </div>
      <div
        onMouseEnter={() => dispatch(toggleElementHoverState(true))}
        onMouseLeave={() => dispatch(toggleElementHoverState(false))}
        className='about__presentation'
      >
        <div ref={portraitRef} className='about__portrait-container'>
          <img
            ref={portraitImgRef}
            className='about__portrait'
            src='https://res.cloudinary.com/c1oud9/image/upload/c_scale,w_999/v1622329699/portfolio/IMG_4191_3_sriugh.jpg'
            alt='my portrait'
          />
        </div>
        <div ref={introTextRef} className='about__text-container'>
          <p className='about__paragraph-important'>
            {' '}
            I&apos;m <span className='about__underline'>Cristian</span>, a
            talented front-end / full-stack developer.
          </p>
          <p className='about__paragraph'>
            I live in sunny 🌞 southern Spain 💃🏼 embracing the quiet village
            life by the sea 🌊.
          </p>
          <p className='about__paragraph'>
            I am fluent in Spanish, English, Romanian and JavaScript 🤓.
          </p>
          <p className='about__paragraph'>
            I love to spend my time{' '}
            <strong className='about__underline about__underline--forth'>
              learning
            </strong>{' '}
            ,{' '}
            <strong className='about__underline about__underline--third'>
              building
            </strong>{' '}
            , and{' '}
            <strong className=' about__underline about__underline--fifth'>
              deploying
            </strong>{' '}
            front-end / full-stack web applications, and I feel lucky to have
            found my passion.💻🔥
          </p>

          <p className='about__paragraph'>
            In my 3+ years of coding experience, I had a front seat 🪑 in this
            highly dynamic environment.
          </p>
          <p className='about__paragraph'>
            In time ⏳, I got to realize that to be efficient in this
            environment the number of known languages is no longer an indicator
            📈 of one&apos;s factual skill, instead adaptability and the
            capability to absorb and understand the emerging new information at
            its fundamental level has lot more significance.
          </p>
          <p className='about__paragraph'>
            {' '}
            More than that, the Front-End work requires a little bit extra, it
            needs a sense of{' '}
            <strong className='about__underline about__underline--fifth'>
              beauty
            </strong>{' '}
            ,
            <strong className='about__underline about__underline--second'>
              {' '}
              symmetry
            </strong>
            , and{' '}
            <strong className='about__underline about__underline--third'>
              coherence
            </strong>{' '}
            , it needs
            <strong className='about__underline about__underline--forth'>
              {' '}
              <em style={{ letterSpacing: '1px', fontSize: '1.2em' }}>
                STYLE!
              </em>
            </strong>{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
