import PropTypes from 'prop-types';
import React from 'react';

import { useDispatch } from 'react-redux';
import { toggleElementClickState } from '../../redux/pointer/pointer.actions';
const ContactBtn = ({ text, hrefUrl, args }) => {
  //! Hooks
  const dispatch = useDispatch();
  return (
    <a
      onMouseEnter={() => dispatch(toggleElementClickState(true))}
      onMouseLeave={() => dispatch(toggleElementClickState(false))}
      href={hrefUrl}
      target={args}
      className='contact-btn__button contact-btn__nav-link'
    >
      <div className='contact-btn__bottom' />
      <div className='contact-btn__top'>
        <div className='contact-btn__label'>{text}</div>
        <div className='contact-btn__button-border contact-btn__button-border-left' />
        <div className='contact-btn__button-border contact-btn__button-border-top' />
        <div className='contact-btn__button-border contact-btn__button-border-right' />
        <div className='contact-btn__button-border contact-btn__button-border-bottom' />
      </div>
    </a>
  );
};

ContactBtn.propTypes = {
  text: PropTypes.string.isRequired,
  hrefUrl: PropTypes.string.isRequired,
  args: PropTypes.string.isRequired,
};

export default ContactBtn;
