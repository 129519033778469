import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as DrawArrow } from '../../assets/svg/arrow_draw.svg';

import { FiLinkedin, FiTwitter, FiGithub } from 'react-icons/fi';

import { useDispatch } from 'react-redux';
import {
  toggleElementClickState,
  toggleElementHoverState,
} from '../../redux/pointer/pointer.actions';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
//* Email library for form submit*/
import emailjs, { init } from 'emailjs-com';

import SendingMessage from '../../modals/sending-message/SendingMessage';
//! ========================================================================
const ContactSection = () => {
  init('user_z91S8Zk2ajqPwlHoAbfrW');

  const [isClicked, setIsClicked] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [mailSent, setMailSent] = useState(false);

  const socialMediaRef = useRef(null);
  const textMeRef = useRef(null);
  const contactFormRef = useRef(null);

  //! Hooks
  const dispatch = useDispatch();

  //! GSAP declaration
  gsap.registerPlugin(ScrollTrigger);

  //! Handlers

  //! Social media scroll
  useEffect(() => {
    gsap.from(
      socialMediaRef.current,
      {
        scrollTrigger: {
          trigger: textMeRef.current,
          start: 'top center',
          end: '15rem',
          toggleActions: 'play none none reverse',
          // markers: true,
        },
        opacity: 0,
        x: -40,
        duration: 0.75,
      },
      []
    );
  }, []);

  //! Text me scroll
  useEffect(() => {
    gsap.from(
      textMeRef.current,
      {
        scrollTrigger: {
          trigger: textMeRef.current,
          start: 'top center',
          end: '15rem',
          toggleActions: 'play none none reverse',
          // markers: true,
        },
        opacity: 0,
        y: 40,
        duration: 1.5,
      },
      []
    );
  }, []);

  //! Contact form scroll
  useEffect(() => {
    gsap.from(
      contactFormRef.current,
      {
        scrollTrigger: {
          trigger: textMeRef.current,
          start: 'top center',
          end: '15rem',
          toggleActions: 'play none none reverse',
          // markers: true,
        },
        opacity: 0,
        y: -40,
        duration: 1,
      },
      []
    );
  }, []);

  //! Submit message function
  const sendEmail = (e) => {
    e.preventDefault();
    setIsClicked(true);
    emailjs
      .sendForm(
        'service_smcpyia',
        'template_jjwzdgd',
        e.target,
        'user_z91S8Zk2ajqPwlHoAbfrW'
      )
      .then(
        (result) => {
          if (result.status === 200) {
            setMailSent(true);
            setTimeout(() => {
              setIsClicked(false);
              setMailSent(false);
            }, 1000);
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
          } else setMailSent(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <section className='contact-section'>
        <div className='contact-section__form-container'>
          <div ref={socialMediaRef} className='contact-section__social-media'>
            <h1 className='contact-section__social-media--title'>Find me on</h1>
            <div className='contact-section__social-media--links'>
              <a
                className='contact-section__link'
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/in/cristian-buciu/'
              >
                <FiLinkedin
                  onMouseEnter={() => dispatch(toggleElementClickState(true))}
                  onMouseLeave={() => dispatch(toggleElementClickState(false))}
                  className='contact-section__social-media--icon'
                />
                <span className='contact-section__link-text'>LinkedIn</span>
              </a>
              <a
                className='contact-section__link'
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/CristianBuciu'
              >
                <FiTwitter
                  onMouseEnter={() => dispatch(toggleElementClickState(true))}
                  onMouseLeave={() => dispatch(toggleElementClickState(false))}
                  className='contact-section__social-media--icon'
                />
                <span className='contact-section__link-text'>Twitter</span>
              </a>
              <a
                className='contact-section__link'
                target='_blank'
                rel='noreferrer'
                href='https://github.com/CristianBuciu'
              >
                <FiGithub
                  onMouseEnter={() => dispatch(toggleElementClickState(true))}
                  onMouseLeave={() => dispatch(toggleElementClickState(false))}
                  className='contact-section__social-media--icon'
                />
                <span className='contact-section__link-text'>GitHub</span>
              </a>
            </div>
          </div>

          <div className='contact-section__left'>
            <img
              className='contact-section__singing-fish'
              src='https://res.cloudinary.com/c1oud9/image/upload/v1622000202/portfolio/abstract-message_bsxskw.png'
              alt='abstract face'
            />
            <p ref={textMeRef} className='contact-section__text-me'>
              <DrawArrow className='contact-section__draw-arrow' />
              <span className='contact-section__cta-text'>Let&apos;s talk</span>
              <br />
              Send me a message using the form or send me an{' '}
              <a
                onMouseEnter={() => dispatch(toggleElementClickState(true))}
                onMouseLeave={() => dispatch(toggleElementClickState(false))}
                className='contact-section__email-link'
                href='mailto:admin@cristianbuciu.dev'
              >
                email.
              </a>
            </p>
          </div>

          <div ref={contactFormRef} className='contact-section__right'>
            <form className='contact-section__form' onSubmit={sendEmail}>
              <div style={{ width: '100%' }}>
                <label className='contact-section__label' htmlFor='name'>
                  What is your name?
                </label>
                <input
                  autoComplete='off'
                  onChange={(e) => setName(e.target.value)}
                  onMouseEnter={() => dispatch(toggleElementHoverState(true))}
                  onMouseLeave={() => dispatch(toggleElementHoverState(false))}
                  value={name}
                  required
                  name='name'
                  type='text'
                  placeholder='Name'
                  className='contact-section--input'
                />
              </div>

              <div style={{ width: '100%' }}>
                <label className='contact-section__label' htmlFor='email'>
                  What is your email address?
                </label>
                <input
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onMouseEnter={() => dispatch(toggleElementHoverState(true))}
                  onMouseLeave={() => dispatch(toggleElementHoverState(false))}
                  required
                  name='email'
                  type='email'
                  placeholder='Email address'
                  className='contact-section--input'
                />
              </div>
              <div style={{ width: '100%' }}>
                <label className='contact-section__label' htmlFor='subject'>
                  What is the subject of the message?
                </label>
                <input
                  autoComplete='off'
                  onChange={(e) => setSubject(e.target.value)}
                  onMouseEnter={() => dispatch(toggleElementHoverState(true))}
                  onMouseLeave={() => dispatch(toggleElementHoverState(false))}
                  value={subject}
                  required
                  name='subject'
                  type='text'
                  placeholder='Subject'
                  className='contact-section--input'
                />
              </div>
              <div style={{ width: '100%' }}>
                <label className='contact-section__label' htmlFor='message'>
                  What message do you want to send me?
                </label>
                <textarea
                  data-gramm='false'
                  autoComplete='off'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onMouseEnter={() => dispatch(toggleElementHoverState(true))}
                  onMouseLeave={() => dispatch(toggleElementHoverState(false))}
                  rows='8'
                  required
                  name='message'
                  type='text'
                  placeholder='Message'
                  className='contact-section--input-message'
                ></textarea>
              </div>
              <button
                onMouseEnter={() => dispatch(toggleElementClickState(true))}
                onMouseLeave={() => dispatch(toggleElementClickState(false))}
                className='contact-btn__button contact-btn__nav-link'
              >
                <div className='contact-btn__bottom' />
                <div className='contact-btn__top'>
                  <div className='contact-btn__label'>Get in touch</div>
                  <div className='contact-btn__button-border contact-btn__button-border-left' />
                  <div className='contact-btn__button-border contact-btn__button-border-top' />
                  <div className='contact-btn__button-border contact-btn__button-border-right' />
                  <div className='contact-btn__button-border contact-btn__button-border-bottom' />
                </div>
              </button>
              <p className='contact-section__form-message'>
                Thank you for your time. You will hear from me in 1-2 business
                days.
              </p>
            </form>
          </div>
          {isClicked ? (
            <SendingMessage
              mailSent={mailSent}
              message={mailSent ? 'Message sent' : 'Sending'}
            />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default ContactSection;
