import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ProjectModal from '../../modals/project/ProjectModal';

import { useDispatch } from 'react-redux';
import {
  toggleElementClickState,
  toggleElementHoverState,
} from '../../redux/pointer/pointer.actions';

//! ----------------------------------------------------------------------------
const ProjectCaption = ({
  title,
  link,
  cName,
  caption,
  tech,
  linkColor,
  imagesSrc,
  children,
  subtitle,
  style,
}) => {
  //! Hooks
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  //! Handlers
  const modalToggleOpen = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <div className={cName}>
      <div className='project-caption__title-container'>
        {children}
        <h3 style={style} className='project-caption__project-title'>
          {title}
        </h3>
        <span className='project-caption__project-title--subtitle'>
          {subtitle}
        </span>
      </div>
      <p style={style} className='project-caption__project-subtitle'>
        {caption}
      </p>
      <div className='project-caption__tech'>
        <h3 style={style} className='project-caption__subtitle'>
          Technologies used
        </h3>
        <div className='project-caption__tech--cards'>
          {tech.map((el, idx) => (
            <div
              onMouseEnter={() => dispatch(toggleElementHoverState(true))}
              onMouseLeave={() => dispatch(toggleElementHoverState(false))}
              key={idx}
            >
              <p style={style} className='project-caption__tech--card'>
                {' '}
                {el}
              </p>
            </div>
          ))}
        </div>
      </div>
      <a
        onClick={modalToggleOpen}
        onMouseEnter={() => dispatch(toggleElementClickState(true))}
        onMouseLeave={() => dispatch(toggleElementClickState(false))}
        className={`project-caption__link project-caption__link-text project-caption__link-text--${linkColor} `}
      >
        View
      </a>
      <ProjectModal
        modalOpen={modalOpen}
        modalToggleOpen={modalToggleOpen}
        link={link}
        title={title}
        caption={caption}
        tech={tech}
        images={imagesSrc}
      >
        {children}
      </ProjectModal>
    </div>
  );
};

ProjectCaption.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  cName: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  tech: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  imagesSrc: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
};

export default ProjectCaption;
