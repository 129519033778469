import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { TimelineLite } from 'gsap';

//! Icons
import { BiMailSend } from 'react-icons/bi';

const SendingMessage = ({ message, mailSent }) => {
  const spanDot1Ref = useRef(null);
  const spanDot2Ref = useRef(null);
  const spanDot3Ref = useRef(null);

  //! gsap timeline declaration
  var tl = new TimelineLite({ repeat: -1 });

  useEffect(() => {
    tl.staggerFrom(
      [spanDot1Ref.current, spanDot2Ref.current, spanDot3Ref.current],
      0.6,
      { y: -5, opacity: 0 },
      0.2
    );
  }, []);

  return ReactDOM.createPortal(
    <div className='sending-message'>
      <div className='sending-message__container'>
        <h3 className='sending-text'>
          {' '}
          {message}
          <span ref={spanDot1Ref} className='sending-message__span-1'>
            .
          </span>
          <span ref={spanDot2Ref} className='sending-message__span-2'>
            .
          </span>
          <span ref={spanDot3Ref} className='sending-message__span-3'>
            .
          </span>
        </h3>
        {mailSent ? (
          <BiMailSend className='sending-message__icon' />
        ) : (
          <div className='container'>
            <div className='box'>
              <div className='border one' />
              <div className='border two' />
              <div className='border three' />
              <div className='border four' />
              <div className='line one' />
              <div className='line two' />
              <div className='line three' />
            </div>
          </div>
        )}
      </div>
    </div>,
    document.getElementById('send-message-modal')
  );
};

export default SendingMessage;
