import React, { useState, useEffect, useRef } from 'react';

import abstractIdea from '../../assets/abstract-idea.png';

import {
  toggleElementClickState,
  toggleElementHoverState,
} from '../../redux/pointer/pointer.actions';
import { useDispatch } from 'react-redux';

import Scroll from 'react-scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Sling as Hamburger } from 'hamburger-react';

import NameLogo from '../name-logo/NameLogo';

//! ============================================================================

const NavBar = () => {
  // const isMobile = window.matchMedia("(max-device-width: 425px)");

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const navbarRef = useRef(null);
  const nameRef = useRef(null);

  //! GSAP declaration
  gsap.registerPlugin(ScrollTrigger);

  //! Gsap Scroll Use Effect
  useEffect(() => {
    ScrollTrigger.config({
      autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load', // notice "resize" isn't in the list
    });
    gsap.from(
      nameRef.current,
      {
        scrollTrigger: {
          trigger: nameRef.current,
          start: '100px  100px',
          end: '+=200',
          toggleActions: 'play none none reverse',
          // markers: true,
          scrub: 1.5,
          // once: true,
        },

        opacity: 0,
      },
      []
    );
  }, []);

  //! Navbar slide
  useEffect(() => {
    gsap.from(
      navbarRef.current,

      {
        duration: 1,
        opacity: 0,
      },
      []
    );
  }, []);

  //! Smooth Scroll
  const ScrollLink = Scroll.Link;

  const clickHandler = () => {
    setIsOpen(false);
    dispatch(toggleElementClickState(false));
  };

  return (
    <>
      <div ref={navbarRef} className='navbar'>
        {/* Pride flag for june month */}
        {/* <div
          className="js-pride-month-gradient"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "0.8rem",
            background:
              "linear-gradient(90deg, rgb(100, 91, 83) 0%, rgb(235, 82, 82) 18.23%, rgb(247, 143, 47) 34.37%, rgb(244, 193, 81) 48.96%, rgb(82, 187, 118) 66.15%, rgb(38, 165, 215) 82.29%, rgb(224, 105, 183) 100%)",
            zIndex: 1010,
          }}
        /> */}

        <div
          className='js-pride-month-gradient'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '1.2rem',
            background: 'linear-gradient(to bottom, #005BBB 50%, #FFD500 50%)',
            zIndex: 1010,
          }}
        />

        <div
          onMouseEnter={() => dispatch(toggleElementHoverState(true))}
          onMouseLeave={() => dispatch(toggleElementHoverState(false))}
          className='navbar__abstract-eye-container'
        >
          <img
            className='navbar__abstract-eye'
            src='https://res.cloudinary.com/c1oud9/image/upload/v1622693174/portfolio/gallery-69-12_gauu9z-removebg-preview_alpqsv.png'
            alt='abstract eye'
          />
        </div>
        <div ref={nameRef} className='navbar__name-logo'>
          <NameLogo />
        </div>
        <div className='navbar__links-container'>
          <ScrollLink
            onMouseEnter={() => dispatch(toggleElementClickState(true))}
            onMouseLeave={() => dispatch(toggleElementClickState(false))}
            to='presentation-section'
            spy={true}
            duration={2000}
            delay={100}
            smooth='easeInOutQuint'
            activeClass='navbar__active-link'
            className='navbar__text  navbar__ScrollLink navbar__type1  mr-md'
          >
            Who I am
          </ScrollLink>
          <ScrollLink
            onMouseEnter={() => dispatch(toggleElementClickState(true))}
            onMouseLeave={() => dispatch(toggleElementClickState(false))}
            to='work-section'
            spy={true}
            delay={100}
            smooth='easeInOutQuint'
            duration={2000}
            activeClass='navbar__active-link'
            className='navbar__text  navbar__ScrollLink navbar__type1  mr-md'
          >
            What I do
          </ScrollLink>
          <ScrollLink
            onMouseEnter={() => dispatch(toggleElementClickState(true))}
            onMouseLeave={() => dispatch(toggleElementClickState(false))}
            to='technologies-section'
            spy={true}
            delay={100}
            smooth='easeInOutQuint'
            duration={2000}
            activeClass='navbar__active-link'
            className='navbar__text  navbar__ScrollLink navbar__type1  mr-md'
          >
            How I do it
          </ScrollLink>

          <ScrollLink
            onMouseEnter={() => dispatch(toggleElementClickState(true))}
            onMouseLeave={() => dispatch(toggleElementClickState(false))}
            to='contact-section'
            spy={true}
            delay={100}
            smooth='easeInOutQuint'
            duration={2000}
            activeClass='navbar__active-link-cta'
            className='navbar__text navbar__cta navbar__ScrollLink navbar__type3 '
          >
            Contact
          </ScrollLink>
        </div>
        <div
          onMouseEnter={() => dispatch(toggleElementClickState(true))}
          onMouseLeave={() => dispatch(toggleElementClickState(false))}
          className='navbar__mobile-btn'
          style={{ cursor: 'default' }}
        >
          <div className='navbar__hamburger'>
            <Hamburger
              label='Show menu'
              rounded
              color='#1e1d1f'
              size={48}
              toggled={isOpen}
              toggle={setIsOpen}
            />
          </div>
        </div>

        {isOpen ? (
          <div className='hamburger-menu'>
            <ScrollLink
              onClick={() => clickHandler()}
              onMouseEnter={() => dispatch(toggleElementClickState(true))}
              onMouseLeave={() => dispatch(toggleElementClickState(false))}
              to='presentation-section'
              spy={true}
              delay={100}
              smooth='easeInOutQuint'
              duration={2000}
              activeClass='navbar__active-link'
              className='navbar__text  navbar__ScrollLink navbar__type1 navbar__custom-menu-link mt-lg'
            >
              Who I am
            </ScrollLink>
            <ScrollLink
              onClick={() => clickHandler()}
              onMouseEnter={() => dispatch(toggleElementClickState(true))}
              onMouseLeave={() => dispatch(toggleElementClickState(false))}
              to='work-section'
              spy={true}
              delay={100}
              smooth='easeInOutQuint'
              duration={2000}
              activeClass='navbar__active-link'
              className='navbar__text  navbar__ScrollLink navbar__type1 navbar__custom-menu-link '
            >
              What I do
            </ScrollLink>
            <ScrollLink
              onClick={() => clickHandler()}
              onMouseEnter={() => dispatch(toggleElementClickState(true))}
              onMouseLeave={() => dispatch(toggleElementClickState(false))}
              to='technologies-section'
              spy={true}
              delay={100}
              smooth='easeInOutQuint'
              duration={2000}
              activeClass='navbar__active-link'
              className='navbar__text  navbar__ScrollLink navbar__type1 navbar__custom-menu-link '
            >
              How I do it
            </ScrollLink>

            <ScrollLink
              onClick={() => clickHandler()}
              onMouseEnter={() => dispatch(toggleElementClickState(true))}
              onMouseLeave={() => dispatch(toggleElementClickState(false))}
              to='contact-section'
              spy={true}
              delay={100}
              smooth='easeInOutQuint'
              duration={2000}
              activeClass='navbar__active-link-cta'
              className='navbar__text navbar__cta navbar__ScrollLink navbar__type3 navbar__custom-menu-link'
            >
              Contact
            </ScrollLink>
            <img
              src={abstractIdea}
              alt='abstract idea'
              className={'navbar__abstract-idea'}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NavBar;
