import React from 'react';
import PropTypes from 'prop-types';

const ProjectCard = ({ imgSrc, cName }) => {
  return (
    <div className={`project-card ${cName}`}>
      <div className='project-card__img-container'>
        <img className={'project-card__image '} src={imgSrc} alt='image' />
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  cName: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default ProjectCard;
