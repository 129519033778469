import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { toggleElementClickState } from '../../redux/pointer/pointer.actions';

//! Component

//! Icons
import { BiLinkExternal } from 'react-icons/bi';

//! Additional packages
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const TechIcon = ({ iconSrc, title, subtitle, link }) => {
  //! Hooks
  const dispatch = useDispatch();

  gsap.registerPlugin(ScrollTrigger);

  return (
    <div
      onMouseEnter={() => dispatch(toggleElementClickState(true))}
      onMouseLeave={() => dispatch(toggleElementClickState(false))}
      onClick={() => {
        window.open(link, '_blank').focus();
      }}
      className='tech-icon'
    >
      <div className='tech-icon__image-container'>
        <img className='tech-icon__image' src={iconSrc} alt={title} />
      </div>
      <div className='tech-icon__text-container'>
        <h3 className='tech-icon__title'>
          {title}&nbsp;
          <span className='tech-icon__external-link'>
            <BiLinkExternal />
          </span>
        </h3>
        <p className='tech-icon__subtitle'>{subtitle}</p>
      </div>
    </div>
  );
};

TechIcon.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default TechIcon;
