import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "react-typist/dist/Typist.css";
import "./sass/main.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store.js";
ReactDOM.render(
	<>
		<Router>
			<React.StrictMode>
				<Provider store={store}>
					<App />
				</Provider>
			</React.StrictMode>
		</Router>
	</>,
	document.getElementById("root")
);
